import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import star_fill from '../../img/star_fill.png'
import star_lineal from '../../img/star_lineal.png'
import goldStar from '../../img/gold-star.png'
import goldStarEyes from '../../img/gold-star-bigeyes.png'
import { XCircleIcon } from '@heroicons/react/solid'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import './Reviews.css'

class Reviews extends Component {
	constructor(props) {
		super(props)
		this.state = {
			review: undefined,
			question: -1,
			notation: 0,
			select: null,
			text: '',
			attempt_submit: false,
			redirect: false,
			error: null
		}
		this.max_length = 2000
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg })
	}

	clearFlash = () => {
		this.setState({ error: null })
	}

	componentDidMount() {
		console.log("reviews", this.props.history.location.pathname)
		this.loadReview()
		this.props.updateLogs(this.props.history.location.pathname, 'Reviews')
		this.props.updateShell(null, true, this.props.history.location.pathname)
		this.props.fetchNotifications()
	}

	loadReview = () => {
		let url = this.props.history.location.pathname
		const secondSep = url.indexOf('/', 1)
		const thirdSep = url.indexOf('/', secondSep + 1)
		const hash = url.substr(thirdSep + 1)
		console.log("hash", hash)
		url = url.substr(0, thirdSep)
		this.props.fetchReviewByURL(url, hash)
		.then(review => {
			console.log(review)
			this.setState({ review: review })
		})
		.catch(err => {
			console.log("could not find review", url)
		})
	}

	handleText = (event) => {
		this.clearFlash()
		this.setState({ text: event.target.value })
	}

	clickSelect = (value) => {
		this.clearFlash()
		this.setState({ select: value })
	}

	clickNotation = (note) => {
		this.clearFlash()
		this.setState({ notation: note })
	}

	clickContinue = () => {
		let review = this.state.review
		let question_nb = this.state.question
		const question = review.questions[question_nb]
		if (question.type === "notation")
		{
			if (this.state.notation === 0 && question.mandatory === true)
				this.setErrorMsg("Champ obligatoire")
			else
			{
				if (review.answers[question_nb] !== undefined)
					review.answers[question_nb] = this.state.notation
				else
					review.answers.push(this.state.notation)
				question_nb++
				this.setState({ notation: 0, question: question_nb })
			}
		}
		else if (question.type === "select")
		{
			if (this.state.select === null && question.mandatory === true)
				this.setErrorMsg("Champ obligatoire")
			else
			{
				if (review.answers[question_nb] !== undefined)
					review.answers[question_nb] = this.state.select
				else
					review.answers.push(this.state.select)
				question_nb++
				this.setState({ select: '', question: question_nb })
			}
		}
		else if (question.type === "text")
		{
			if (this.state.text.length === 0 && question.mandatory === true)
				this.setErrorMsg("Champ obligatoire")
			else if (this.state.text.length > this.max_length)
				this.setErrorMsg(this.max_length + " caractères maximum")
			else
			{
				if (review.answers[question_nb] !== undefined)
					review.answers[question_nb] = this.state.text
				else
					review.answers.push(this.state.text)
				question_nb++
				this.setState({ text: '', question: question_nb })
			}
		}
	}

	clickBack = () => {
		this.clearFlash()
		if (this.state.question > 0)
		{
			const question_nb = this.state.question - 1
			let text = ''
			let notation = 0
			let select = null
			const review = this.state.review
			const question = review.questions[question_nb]
			if (question.type === "text")
				text = review.answers[question_nb]
			else if (question.type === "select")
				select = review.answers[question_nb]
			else if (question.type === "notation")
				notation = review.answers[question_nb]
			if (review.answers[this.state.question])
				review.answers.splice(this.state.question, 1)
			this.setState({ question: question_nb, select: select, text: text, notation: notation, review: review })
		}
	}

	clickSubmit = () => {
		this.setState({ attempt_submit: true })
		this.clickContinue()
		const review = {
			_id: this.state.review._id,
			answers: this.state.review.answers
		}
		this.props.postSubmitReview(review)
		.then(review => {
			this.setState({ attempt_submit: false })
		})
		.catch(err => {
			console.log("could not submit review", err)
		})
	}

	clickStart = () => {
		this.setState({ question: 0 })
	}

	clickFinish = () => {
		this.setState({ redirect: true })
	}

	render() {
		const review = this.state.review
		const lang_assets = this.props.lang_assets
		let question_nb = this.state.question
		if (this.state.redirect === true || this.props.features !== "premium")
		{
			return (
				<Redirect to="/" />
				)
		}
		if (!review)
		{
			return (
				<div className="loading">
					<Loader />
				</div>
			)
		}
		else if (question_nb === review.questions.length || review.completed !== false) //DERNIER SCREEN
		{
			const color = { backgroundColor: '#' + this.props.theme.color2 }
			return (
				<div className="min-h-4/5-screen">
					<div className="header flex justify-center items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
					{/* <div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}> */}
						<h1 className="text-white text-3xl">{review.title}</h1>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<img alt="star" src={goldStarEyes} className="w-32 flex mt-10 mx-auto" />
						<h1 className="mt-8 text-center text-2xl font-semibold tracking-tight text-gray-800">{lang_assets.reviews.thanks}</h1>
						<div className="fixed w-11/12 top-3/4 m-auto">
							<button type="button" style={color} className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl" onClick={this.clickFinish}>{lang_assets.reviews.finish}</button>
						</div>
					</div>
				</div>
			)
		}
		else if (question_nb === -1) //SCREEN INTRO
		{
			return (
				<div className="min-h-4/5-screen">
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{review.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<img alt="star" className="w-36 mt-5 mx-auto" src={goldStar} />
						<p className="font-semibold text-base text-center mt-10 mx-3">{review.introduction}</p>
						<div className="fixed w-11/12 top-3/4 m-auto">
							<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" onClick={this.clickStart} type="button" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.reviews.start}</button>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			const question = review.questions[question_nb]
			let answerJSX = null
			if (question.type === "notation")
			{
				let stars = []
				for (let i = 0; i < 5; i++)
				{
					if (i < this.state.notation)
						stars.push(star_fill)
					else
						stars.push(star_lineal)
				}
				let notation_txt = null
				if (this.state.notation > 0)
					notation_txt = this.props.lang_assets.reviews.notation_txt[this.state.notation - 1]
				answerJSX = (
					<div>
						<p className="flex justify-center space-x-3">
							<img className='w-10' src={stars[0]} alt="note 1" onClick={this.clickNotation.bind(this, 1)} />
							<img className='w-10' src={stars[1]} alt="note 2" onClick={this.clickNotation.bind(this, 2)} />
							<img className='w-10' src={stars[2]} alt="note 3" onClick={this.clickNotation.bind(this, 3)} />
							<img className='w-10' src={stars[3]} alt="note 4" onClick={this.clickNotation.bind(this, 4)} />
							<img className='w-10' src={stars[4]} alt="note 5" onClick={this.clickNotation.bind(this, 5)} />
						</p>
						<p className="text-base text-center mt-8 italic">{notation_txt}</p>
					</div>
				)
			}
			else if (question.type === "select")
			{
				const qcm = question.select_list.split(',')
				answerJSX = (
					<div>
						{qcm.map((value) => {
							let classname = "border-2 border-gray-300 h-16 rounded-full my-4 mx-3 text-gray-400"
							let bg = null
							if (value === this.state.select)
							{
								classname = "filter drop-shadow h-16 rounded-full my-4 mx-3 text-white"
								bg = { backgroundColor: '#' + this.props.theme.color2 }
							}
							const txt = value.charAt(0).toUpperCase() + value.slice(1)
							return (
								<div 
									className={classname}
									style={bg}
									key={value} 
									onClick={this.clickSelect.bind(this, value)}
								>
									<p className="h-full flex items-center justify-center ml-4 font-semibold text-lg">{txt}</p>
								</div>
							)
						})}
					</div>
				)
			}
			else if (question.type === "text")
			{
				answerJSX = (
					<div>
						<label htmlFor="comment" className="block text-sm font-medium text-gray-700">
							Votre réponse
						</label>
						<div className="mt-1">
							<textarea
								rows={4}
								value={this.state.text}
								id="comment"
								onChange={this.handleText}
								className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
							/>
						</div>
					</div>
				)
			}
			let error = null
			if (this.state.error)
			{
				error = (
					<div className="rounded-md bg-red-50 p-4 mt-3">
						<div className="flex">
							<div className="flex-shrink-0">
								<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
							</div>
							<div className="ml-3">
								<h3 className="text-sm font-medium text-red-800">{this.state.error}</h3>
							</div>
						</div>
					</div>
				)
			}
			question_nb++
			let backbtn = null
			if (question_nb > 1)
				backbtn = (
					<div className="z-30 w-full top-8 fixed block">
						<button 
							type="button" 
							style={{ backgroundColor: '#' + this.props.theme.color1 }} 
							className="inline-flex mt-3 ml-2 z-30 items-center p-3 border border-transparent shadow-previousbtn rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2" 
							onClick={this.clickBack}>
								<ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
				)
			let continuebtn = (
				<div className="fixed w-11/12 top-3/4 m-auto">
					<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" onClick={this.clickContinue} type="button" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.reviews.continue}</button>
				</div>
			)
			if (question_nb === review.questions.length)
				continuebtn = (
					<div className="fixed w-11/12 top-3/4 m-auto">
						<button className="font-semibold py-5 w-full rounded-full text-white shadow-xl text-xl my-10 waves-effect waves-light" onClick={this.clickSubmit} type="button" style={{backgroundColor: "#" + this.props.theme.color3}}>{lang_assets.reviews.continue}</button>
					</div>
				)
			let buttons = (
				<div>
					{backbtn}
					{continuebtn}
				</div>
			)
			if (this.state.attempt_submit === true)
			{
				buttons = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			let question_title = null
			for (let i = 0; i < question.translations.length; i++)
			{
				if (question.translations[i].lang === review.lang)
				{
					question_title = question.translations[i].title
					break
				}
			}
			return (
				<div className="min-h-4/5-screen">
					<div className="flex justify-center	items-center z-0 w-full fixed top-0 h-36" style={{ backgroundColor: '#' + this.props.theme.color1 }}>
						<div className="text-white text-3xl">
							<h1>{review.title}</h1>
						</div>
					</div>
					<div className="bg-white w-full pt-10 px-5 mt-32 min-h-9/10-screen absolute rounded-t-3xl pb-10">
						<h1 className="font-semibold text-2xl mt-10 text-center">{question_title}</h1>
						<h2 className="italic text-gray-400 text-sm text-center">{lang_assets.reviews.question} {question_nb} {lang_assets.reviews.of} {review.questions.length}</h2>
						<div className="mt-12">
							{answerJSX}
						</div>
						{error}
						{buttons}
					</div>
				</div>
			)
		}
	}
}

export default Reviews